<template>
  <div class="payments__page">
    <Redirect class="" title="Settings" route="website.settings" />
    <v-col class="page-header" cols="12">
      <h2 class="page-title">Proxy Management</h2>
    </v-col>
    <span class="label-form"> Private Code </span>
    <v-text-field class="mt-2 text-field-color" v-model="privateCode"></v-text-field>
    <v-btn color="primary" :disabled="!privateCode" @click="getProxy"> Get Groups </v-btn>
    <v-divider class="mt-4 mb-4"></v-divider>
    <v-btn color="primary" :disabled="!privateCode" @click="addNewGroup"> Add New Group </v-btn>
    <v-row>
      <v-col cols="12">
        <b>Your Groups</b>
        <v-data-table
          :loading="loadingItems"
          :headers="headers"
          :items="proxyGroups"
          :items-per-page="filter['limit']"
          :hide-default-footer="true"
          :page.sync="filter['page']"
          item-key="id"
        >
          <template v-slot:[`item.proxyStr`]="{ item }">
            <v-data-table
              :headers="proxyHeaders"
              :items="item.proxies"
              :items-per-page="50"
              :hide-default-footer="true"
              item-key="_id"
            >
            </v-data-table>
          </template>
          <template v-slot:[`item._source.action`]="{ item }">
            <v-btn small color="primary" @click="onEditGroup(item)">Edit</v-btn>
            <v-btn small class="ml-4" color="primary" @click="addGroupToStore(item)">Add Store</v-btn>
          </template>
        </v-data-table>
        <v-pagination v-model="filter.page" :length="filter.total"> </v-pagination>
      </v-col>
      <v-col cols="12">
        <b>Store Proxy Group</b>
        <v-data-table
          :loading="loadingItems"
          :headers="headers"
          :items="proxyGroupsAdded"
          :items-per-page="filterStore['limit']"
          :hide-default-footer="true"
          :page.sync="filterStore['page']"
          item-key="id"
        >
          <template v-slot:[`item.proxyStr`]="{ item }">
            <v-data-table
              :headers="proxyHeaders"
              :items="item.proxies"
              :items-per-page="50"
              :hide-default-footer="true"
              item-key="_id"
            >
            </v-data-table>
          </template>
          <template v-slot:[`item._source.action`]="{ item }">
            <v-btn small class="ml-4" @click="removeGroupStore(item)">Remove</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog class="pa-5" width="1200" height="100%" v-model="showConfigGroupDialog">
      <v-card v-if="group" class="px-5 py-5" relative>
        <h3 class="pl-0 pt-0 mb-3 text-center">{{ group.name == '' ? 'Add New Group' : 'Edit Group' }}</h3>
        <div class="close-icon" @click="showConfigGroupDialog = false">
          <v-icon>mdi-close</v-icon>
        </div>
        <v-row>
          <v-col sm="12">
            <div>
              <span><span style="color: red">(*)</span> Name </span>
              <v-text-field type="text" v-model="group.name"></v-text-field>
            </div>
          </v-col>
          <v-col sm="12">
            <ProxyTable :group="group" :code="privateCode" @changeProxyGroup="changeProxyGroup($event)" />
          </v-col>
          <v-col cols="12">
            <v-card-actions class="px-0 mt-2">
              <v-spacer></v-spacer>
              <v-btn class="btn" color="primary" @click="onSaveGroup">Save</v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiProxy } from '@/apis/proxy';
import STORAGE_NAME from '@/const/storage';
import Redirect from '@/components/RedirectTo';
import ProxyTable from './proxy/ProxyTable.vue';

export default {
  components: {
    Redirect,
    ProxyTable,
  },
  data() {
    return {
      showConfigGroupDialog: false,
      group: {
        _id: null,
        name: null,
      },
      privateCode: '',
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Proxies',
          value: 'proxyStr',
        },
        {
          text: 'Action',
          value: '_source.action',
        },
      ],
      proxyHeaders: [
        {
          text: 'Proxy',
          value: 'proxyId.proxyName',
        },
        {
          text: 'Domain',
          value: 'proxyId.proxyDomain',
        },
        {
          text: 'Rotation Type',
          value: 'rotationType',
        },
        {
          text: 'Rotation Value',
          value: 'rotationValue',
        },
      ],
      loadingItems: false,
      filter: {
        page: 1,
        limit: 5,
        name: null,
        storeId: null,
        code: null,
        total: 0,
      },
      filterStore: {
        page: 1,
        limit: 5,
        name: null,
        storeId: null,
        code: null,
      },
      proxyGroups: [],
      proxyGroupsAdded: [],
    };
  },

  methods: {
    async getProxy() {
      const storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
      this.filter.storeId = storeId;
      this.filterStore.storeId = storeId;
      this.loadingItems = true;
      await this.refreshGroup();
      this.loadingItems = false;
    },
    async refreshGroup() {
      await this.getListGroup();
      await this.getAddedListGroup();
    },
    async getListGroup() {
      this.filter.code = this.privateCode;
      this.group.team = this.privateCode;
      const { data } = await apiProxy.listGroups(this.filter);
      this.proxyGroups = data.groups;
      let total = data?.pagination.total;
      let limit = data?.pagination.limit;
      this.filter.total = parseInt(total / limit) + 1;
    },
    async getAddedListGroup() {
      this.filterStore.code = this.privateCode;
      const { data } = await apiProxy.listGroupsAdded(this.filterStore);
      this.proxyGroupsAdded = data.groups;
    },
    async addGroupToStore(group) {
      let params = {
        groupId: group.id,
      };
      const d = await apiProxy.addGroupToStore(params);
      await this.refreshGroup();
    },
    async removeGroupStore(group) {
      let params = {
        groupId: group.id,
      };
      const d = await apiProxy.removeGroupStore(params);
      await this.refreshGroup();
    },
    async onSaveGroup() {
      if (!this.group.id || !this.group.name) {
        console.log('DEBUG: id, name is not null');
        return;
      }
      let params = {
        id: this.group.id,
        name: this.group.name,
        proxies: this.proxyGroupData,
        team: this.privateCode,
      };
      const d = await apiProxy.saveProxyGroup(params);
      this.showConfigGroupDialog = false;
      await this.refreshGroup();
    },
    async onEditGroup(item) {
      this.showConfigGroupDialog = true;
      this.group.id = item.id;
      this.group.name = item.name;
    },
    async addNewGroup() {
      this.showConfigGroupDialog = true;
      this.group.id = '-1';
      this.group.name = '';
    },
    changeProxyGroup(proxyList) {
      this.proxyGroupData = proxyList;
    },
  },
  watch: {
    showConfigGroupDialog() {
      if (this.showConfigGroupDialog == false) {
        this.group = { id: '-1', name: null };
      }
    },
    'filter.page': async function() {
      this.loadingItems = true;
      await this.getListGroup();
      this.loadingItems = false;
    },
  },
};
</script>
<style lang="scss">
.payments__page {
  .v-tooltip__content {
    z-index: 10;
    opacity: 1 !important;
  }
  .btn-icon-infor {
    background-color: transparent !important;
    color: #333 !important;
    box-shadow: none;
    font-size: 13px;
    min-width: 20px !important;
  }
  .btn-icon-infor:hover {
    background-color: #fff !important;
  }
  .btn-icon-infor:before {
    background-color: #fff !important;
  }
}
.v-tooltip__content {
  z-index: 10;
  opacity: 1 !important;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payments__page"},[_c('Redirect',{attrs:{"title":"Settings","route":"website.settings"}}),_c('v-col',{staticClass:"page-header",attrs:{"cols":"12"}},[_c('h2',{staticClass:"page-title"},[_vm._v("Proxy Management")])]),_c('span',{staticClass:"label-form"},[_vm._v(" Private Code ")]),_c('v-text-field',{staticClass:"mt-2 text-field-color",model:{value:(_vm.privateCode),callback:function ($$v) {_vm.privateCode=$$v},expression:"privateCode"}}),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.privateCode},on:{"click":_vm.getProxy}},[_vm._v(" Get Groups ")]),_c('v-divider',{staticClass:"mt-4 mb-4"}),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.privateCode},on:{"click":_vm.addNewGroup}},[_vm._v(" Add New Group ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v("Your Groups")]),_c('v-data-table',{attrs:{"loading":_vm.loadingItems,"headers":_vm.headers,"items":_vm.proxyGroups,"items-per-page":_vm.filter['limit'],"hide-default-footer":true,"page":_vm.filter['page'],"item-key":"id"},on:{"update:page":function($event){return _vm.$set(_vm.filter, 'page', $event)}},scopedSlots:_vm._u([{key:"item.proxyStr",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{attrs:{"headers":_vm.proxyHeaders,"items":item.proxies,"items-per-page":50,"hide-default-footer":true,"item-key":"_id"}})]}},{key:"item._source.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.onEditGroup(item)}}},[_vm._v("Edit")]),_c('v-btn',{staticClass:"ml-4",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.addGroupToStore(item)}}},[_vm._v("Add Store")])]}}],null,true)}),_c('v-pagination',{attrs:{"length":_vm.filter.total},model:{value:(_vm.filter.page),callback:function ($$v) {_vm.$set(_vm.filter, "page", $$v)},expression:"filter.page"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v("Store Proxy Group")]),_c('v-data-table',{attrs:{"loading":_vm.loadingItems,"headers":_vm.headers,"items":_vm.proxyGroupsAdded,"items-per-page":_vm.filterStore['limit'],"hide-default-footer":true,"page":_vm.filterStore['page'],"item-key":"id"},on:{"update:page":function($event){return _vm.$set(_vm.filterStore, 'page', $event)}},scopedSlots:_vm._u([{key:"item.proxyStr",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{attrs:{"headers":_vm.proxyHeaders,"items":item.proxies,"items-per-page":50,"hide-default-footer":true,"item-key":"_id"}})]}},{key:"item._source.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ml-4",attrs:{"small":""},on:{"click":function($event){return _vm.removeGroupStore(item)}}},[_vm._v("Remove")])]}}],null,true)})],1)],1),_c('v-dialog',{staticClass:"pa-5",attrs:{"width":"1200","height":"100%"},model:{value:(_vm.showConfigGroupDialog),callback:function ($$v) {_vm.showConfigGroupDialog=$$v},expression:"showConfigGroupDialog"}},[(_vm.group)?_c('v-card',{staticClass:"px-5 py-5",attrs:{"relative":""}},[_c('h3',{staticClass:"pl-0 pt-0 mb-3 text-center"},[_vm._v(_vm._s(_vm.group.name == '' ? 'Add New Group' : 'Edit Group'))]),_c('div',{staticClass:"close-icon",on:{"click":function($event){_vm.showConfigGroupDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('div',[_c('span',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("(*)")]),_vm._v(" Name ")]),_c('v-text-field',{attrs:{"type":"text"},model:{value:(_vm.group.name),callback:function ($$v) {_vm.$set(_vm.group, "name", $$v)},expression:"group.name"}})],1)]),_c('v-col',{attrs:{"sm":"12"}},[_c('ProxyTable',{attrs:{"group":_vm.group,"code":_vm.privateCode},on:{"changeProxyGroup":function($event){return _vm.changeProxyGroup($event)}}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-actions',{staticClass:"px-0 mt-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"btn",attrs:{"color":"primary"},on:{"click":_vm.onSaveGroup}},[_vm._v("Save")])],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
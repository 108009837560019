<template>
  <div>
    <v-row>
      <v-col :cols="5">
        <span>Proxy List</span>
        <v-data-table
          :items="proxyList"
          :headers="proxyAvaiableHeaders"
          :page.sync="filter.page"
          :items-per-page="filter.limit"
          :hide-default-footer="true"
          :loading="loadingItems"
          :disabled="loadingItems"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn small color="primary" @click="addProxy(item)">Add</v-btn>
          </template>
        </v-data-table>
        <v-pagination
          v-model="filter.page"
          :length="filter.total"
          :total-visible="5"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        ></v-pagination>
      </v-col>
      <v-col :cols="7">
        <span>Proxy Group</span>
        <v-data-table
          :items="addedProxyList"
          :headers="proxyHeaders"
          :items-per-page="filterGroup.limit"
          :hide-default-footer="true"
          :loading="loadingGroupItems"
          :disabled="loadingGroupItems"
        >
          <template v-slot:[`item.rotationType`]="{ item }">
            {{ item.rotationType }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn small color="primary" @click="showProxyConfigDlg(item)">Edit</v-btn>
            <v-btn small color="red" class="ml-4" @click="removeProxy(item)">Remove</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog class="pa-5" width="400" v-model="showDialogConfig">
      <v-card>
        <v-card-title
          >Proxy Config
          <div class="close-icon" @click="showDialogConfig = false">
            <v-icon>mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text>
          <div>
            <span>Rotation Type </span>
            <v-combobox v-model="proxy.rotationType" :items="rotationTypes"></v-combobox>
          </div>
          <div>
            <span>Rotation Value </span>
            <v-text-field type="text" v-model="proxy.rotationValue"></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="onSaveProxyConfig()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { apiProxy } from '@/apis/proxy';

export default {
  data() {
    return {
      proxyList: [],
      addedProxyList: [],
      rotationTypes: ['order', 'purchase', 'auto'],
      filter: {
        page: 1,
        limit: 10,
        total: 0,
        groupId: null,
        code: null,
      },
      filterGroup: {
        page: 1,
        limit: 10,
        total: 0,
        groupId: null,
        code: null,
      },
      proxy: {},
      showDialogConfig: false,
      loadingItems: false,
      loadingGroupItems: false,
      proxyAvaiableHeaders: [
        {
          text: 'Name',
          value: 'proxyName',
        },
        {
          text: 'Type',
          value: 'proxyType',
        },
        {
          text: 'Action',
          value: 'action',
        },
      ],
      proxyHeaders: [
        {
          text: 'Name',
          value: 'proxyId.proxyName',
        },
        {
          text: 'Rotation Type',
          value: 'rotationType',
        },
        {
          text: 'Rotation Value',
          value: 'rotationValue',
        },
        {
          text: 'Action',
          value: 'action',
        },
      ],
    };
  },
  props: {
    group: Object,
    code: String,
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if (!this.group.id) return;
      this.filter.groupId = this.group.id || null;
      this.filter.code = this.code || null;
      this.filterGroup.groupId = this.group.id || null;
      this.filterGroup.code = this.code || null;

      await this.getRemainProxies();
      if (this.group.id !== '-1') await this.getGroupProxies();
      else this.addedProxyList = [];
    },
    async getRemainProxies() {
      this.loadingItems = true;
      try {
        const { data } = await apiProxy.listRemainProxies(this.filter);
        this.proxyList = data.proxies;

        let total = data?.pagination.total;
        let limit = data?.pagination.limit;
        this.filter.total = parseInt(total / limit) + 1;
      } catch (e) {
        this.proxyList = [];
      }
      this.loadingItems = false;
    },
    async getGroupProxies() {
      this.loadingGroupItems = true;
      const { data } = await apiProxy.listProxiesGroup(this.filterGroup);
      let groups = data.groups || [];
      this.addedProxyList = groups.length > 0 ? groups[0].proxies : [];
      //   let total = data?.pagination.total;
      //   let limit = data?.pagination.limit;
      //   this.filterGroup.total = parseInt(total / limit) + 1;
      this.loadingGroupItems = false;
    },
    addProxy(proxy) {
      if (!this.proxyList) this.proxyList = [];
      let findProxy = this.addedProxyList.filter(p => p._id === proxy._id) || [];
      if (findProxy.length == 0) {
        this.addedProxyList.push({
          _id: proxy._id,
          rotationType: 'order',
          rotationValue: 0,
          proxyId: {
            _id: proxy._id,
            proxyDomain: proxy.proxyDomain,
            proxyName: proxy.proxyName,
            proxyType: proxy.proxyType,
            isPrivateProxy: proxy.isPrivateProxy,
            code: this.code,
          },
        });
        for (var j = 0; j < this.proxyList.length; j++) {
          const p = this.proxyList[j];
          if (p._id === proxy._id) {
            this.proxyList.splice(j, 1);
            break;
          }
        }
      }
    },
    removeProxy(proxy) {
      if (!this.addedProxyList) this.addedProxyList = [];
      for (var i = 0; i < this.addedProxyList.length; i++) {
        const p = this.addedProxyList[i];
        if (p._id === proxy._id) {
          this.proxyList.push({
            code: this.code,
            isPrivateProxy: proxy.proxyId.isPrivateProxy,
            proxyDomain: proxy.proxyId.proxyDomain,
            proxyName: proxy.proxyId.proxyName,
            proxyType: proxy.proxyId.proxyType,
            _id: p._id,
          });
          this.addedProxyList.splice(i, 1);
          break;
        }
      }
    },
    showProxyConfigDlg(proxy) {
      this.showDialogConfig = true;
      this.proxy = proxy;
    },
    onSaveProxyConfig() {
      let currentProxy = this.addedProxyList.find(p => p._id === this.proxy._id);
      if (currentProxy) {
        currentProxy.rotationType = this.proxy.rotationType;
        currentProxy.rotationValue = this.proxy.rotationValue;
      }
      this.showDialogConfig = false;
    },
  },
  watch: {
    group: {
      handler: function() {
        this.init();
      },
      deep: true,
    },
    addedProxyList: {
      handler: function() {
        this.$emit('changeProxyGroup', this.addedProxyList);
      },
      deep: true,
    },
    'filter.page': async function() {
      await this.getRemainProxies();
    },
  },
};
</script>

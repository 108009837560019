import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/stores/payout/proxy/';

function list(params) {
  return storeRequest.get(path + 'store-proxies', {
    params: { ...params },
  });
}
function get(params) {
  return storeRequest.get(path + 'get-store-proxies', {
    params: { ...params },
  });
}
function listProxies(params) {
  return storeRequest.get(path + 'remain-proxies', {
    params: { ...params },
  });
}
function saveProxies(data) {
  return storeRequest.post(path + 'save-store-proxies', data);
}
function listGroups(params) {
  return storeRequest.get(path + 'list-groups', {
    params: { ...params },
  });
}
function listGroupsAdded(params) {
  return storeRequest.get(path + 'store-groups', {
    params: { ...params },
  });
}
function addGroupToStore(params) {
  return storeRequest.get(path + 'add-store-group', {
    params: { ...params },
  });
}
function removeGroupStore(params) {
  return storeRequest.get(path + 'remove-store-group', {
    params: { ...params },
  });
}
function listRemainProxies(params) {
  return storeRequest.get(path + 'remain-group-proxies', {
    params: { ...params },
  });
}
function listProxiesGroup(params) {
  return storeRequest.get(path + 'list-proxies-groups', {
    params: { ...params },
  });
}
function saveProxyGroup(data) {
  return storeRequest.post(path + 'group/' + data.id, data);
}
export const apiProxy = {
  get,
  list,
  listProxies,
  saveProxies,
  listGroups,
  listGroupsAdded,
  addGroupToStore,
  removeGroupStore,
  listRemainProxies,
  listProxiesGroup,
  saveProxyGroup,
};
